export const spiderData = [
  { year: '2018', title: 'JavaScript', value: 2 },
  { year: '2018', title: 'React', value: 2 },
  { year: '2018', title: 'Redux', value: 1 },
  { year: '2018', title: 'D3', value: 2 },
  { year: '2018', title: 'Jest', value: 1 },
  { year: '2018', title: 'Git', value: 2 },
  { year: '2018', title: 'Figma', value: 1 },
  { year: '2018', title: 'HTML/CSS', value: 4 },

  { year: '2019', title: 'JavaScript', value: 3 },
  { year: '2019', title: 'React', value: 3 },
  { year: '2019', title: 'Redux', value: 2 },
  { year: '2019', title: 'D3', value: 3 },
  { year: '2019', title: 'Jest', value: 1 },
  { year: '2019', title: 'Git', value: 3 },
  { year: '2019', title: 'Figma', value: 2 },
  { year: '2019', title: 'HTML/CSS', value: 4 },

  { year: '2020', title: 'JavaScript', value: 3 },
  { year: '2020', title: 'React', value: 3 },
  { year: '2020', title: 'Redux', value: 3 },
  { year: '2020', title: 'D3', value: 3 },
  { year: '2020', title: 'Jest', value: 2 },
  { year: '2020', title: 'Git', value: 4 },
  { year: '2020', title: 'Figma', value: 2 },
  { year: '2020', title: 'HTML/CSS', value: 5 },

  { year: '2021', title: 'JavaScript', value: 4 },
  { year: '2021', title: 'React', value: 4 },
  { year: '2021', title: 'Redux', value: 4 },
  { year: '2021', title: 'D3', value: 4 },
  { year: '2021', title: 'Jest', value: 2 },
  { year: '2021', title: 'Git', value: 5 },
  { year: '2021', title: 'Figma', value: 3 },
  { year: '2021', title: 'HTML/CSS', value: 5 },

  { year: '2022', title: 'JavaScript', value: 5 },
  { year: '2022', title: 'React', value: 5 },
  { year: '2022', title: 'Redux', value: 4 },
  { year: '2022', title: 'D3', value: 5 },
  { year: '2022', title: 'Jest', value: 3 },
  { year: '2022', title: 'Git', value: 5 },
  { year: '2022', title: 'Figma', value: 3 },
  { year: '2022', title: 'HTML/CSS', value: 5 },
]

export const vennData = {
  uxDesign: {
    title: 'User Experience Design',
    skills: [
      'Information Architecture',
      'Product Design',
      'Service Design',
      'Interaction Design',
      'Visual Design',
      'UI Design',
      'Usability Engineering',
      'Perception, Cognition, Psychology',
      'Human Computer Interaction',
    ],
  },
  digitalTechnology: {
    title: 'Digital Technology',
    skills: [
      'Software Architecture',
      'Software Development',
      'Software Testing',
      'Software Development Lifecycle',
      'UI Technology',
      'Cloud Computing',
      'Cyber Security',
      'Programming Languages',
      'Databases & Modeling',
    ],
  },
}
